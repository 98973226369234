import React  from "react";

import { NewsCategory } from "../../Components/News/NewsCategory";
import { Helmet } from "react-helmet-async";

export  const Literature =()=>{
    
    return(
        <>
        <Helmet>
            <title>साहित्य - SRSkhavar </title>
            <meta
            name="description"
            content="Get all the literature news"
            />
            <meta
            name = "keywords"
            content="srskhavar, srs khavar, srs khabar, news, neplai samachar, samachar, srs, srsnews, srskhabar, online news, online news, onlinekhabar, online khabar, kantipur, nepali news, srskhavar, news, nepali news,  international news, international,*"
            />
            <link rel="canenocial" href="/"/>
        </Helmet>
        <NewsCategory category = "literature"/>
        
        </>
     
        )

}