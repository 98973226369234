import React, {useState,useEffect} from "react";
// import { LatestNewsCard } from "../NewsCards/LatestNewsCard";
import axios from "axios";

import { Link } from "react-router-dom"
import config from '../../Config/config.prod.js';
export const LatestNews = ()=>{
    const apiUrl = config.apiUrl;

    const [latestNews,setLatestNews] = useState([])
    useEffect(()=>{
        async function getLatestNews(){
            try{
                
                const latestNews = await axios.get(`${apiUrl}/news/latest/`);
                setLatestNews(latestNews.data);

            }catch(error){
                console.log(error)
            }
        }
        getLatestNews()
    },[apiUrl])
    
    return(
       
        <>

        <div className='container border-y border-gray-300 xl:flex'>
            <div className="grid gap-8 grid-flow-col grid-cols-12 my-12 max-[928px]:flex max-[928px]:flex-col">
                {latestNews.slice(2,3).map((content,key)=>{
                    return( 
                    <div key={key} className="col-span-7">
                        <img alt={content.title} src={content.photo_url} className="w-full max-h-[600px]" loading="lazy"/>
                        <i className="pt-1 pe-2 fa fa-calendar"> {content.neplai_date_format}</i>

                        <Link to = {`news/${content.news_slug}`} className="pt-8 text-xl font-bold text-justify text-[#23252d] hover:text-[#2260bf] ">
                             <h1> {content.title}</h1>
                        </Link>
                        <Link to = {`news/${content.news_slug}`} className=" hover:text-[#2260bf] "> 
                            <p className=" pt-2 text-justify text-lg">{content.first_paragraph.slice(0,250)} ...</p>
                        </Link> 

                    </div>        
                    )})}

                <div className="flex flex-col justify-between col-span-5 ">

                {latestNews.slice(3,Math.min(latestNews.length, 7)).map((content,key)=>{
                    return( 
                    <div key={key} className="flex max-[767px]:pt-10">
                        <img alt={content.title} src={content.photo_url} className="w-2/6 rounded" loading="lazy"/>
                        <div className="pl-5 pr-8">
                         <Link to = {`news/${content.news_slug}`} className="hover:text-[#2260bf] pt-8 text-xl font-bold text-justify text-[#23252d]"> <h1>{content.title}</h1></Link> 
                        </div>
                    </div>
              
                    )})}

                </div>
            </div>
        </div>
        </>
    )
}
