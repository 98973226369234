import config from '../../Config/config.prod.js';
import React, {useState,useEffect} from "react";
import axios from "axios";

export const Third = ()=>{
    const apiUrl = config.apiUrl;
    const [third,setThird] = useState([]);
    useEffect(()=>{
        async function getAds(){

            try{
                const thirdd = await axios.get(`${apiUrl}/news/gallery/3`);


                setThird(thirdd.data);

            }catch(error){
                
                console.log(error)
            }
        }
        getAds()
    },[apiUrl])



    if (third.length === 0) {
        return (
          <>
          </>
        );
      }
    return (
        <>
      
        <div className='container grid grid-cols-3 gap-7 max-lg:grid-cols-1 '>
            {third.map((content, key) => (

            <img src={content.photo_url} alt="content loaging" className="h-[65vh] max-lg:w-full max-lg:" key = {key}
            loading="lazy"/>
            ))}
        </div>
        </>
    )

}
    //   style={{ maxHeight: "600px",maxWidth: "800px" }}


//   {/* <div className={third.length === 3?'container grid grid-cols-3 gap-3':third.length === 2?'container grid grid-cols-2 gap-3':'container'}> */}
//   <div className='container flex max-md:flex-col max-md:items-center md:h-[95vh] justify-center gap-4'>
//   {third.map((content, key) => (
//           <img src={content.photo_url} alt="content loaging" className="max-md:w-full" key = {key} loading="lazy" 
//           />
//   ))}
// </div>