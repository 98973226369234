import config from '../../Config/config.prod.js';
import React, {useState,useEffect} from "react";
import axios from "axios";

export const Fourth = ()=>{
    const apiUrl = config.apiUrl;
    const [fourth,setFourth] = useState([]);

    useEffect(()=>{
        async function getTodaysNews(){
            try{
                const fourtha = await axios.get(`${apiUrl}/news/gallery/4`);
                setFourth(fourtha.data);

            }catch(error){
                console.log(error)
            }
        }
        getTodaysNews()
    },[apiUrl])


    if (fourth.length === 0) {
        return (
          <>
          </>
        );
      }

    return (
        <div className='container grid grid-cols-3 gap-7 max-lg:grid-cols-1 '>
            {fourth.map((content, key) => (

            <img src={content.photo_url} alt="content loaging" className="h-[65vh] max-lg:w-full max-lg:" key = {key}
            loading="lazy"/>
            ))}
        </div>
    )

}
