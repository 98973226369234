import React, {useState,useEffect} from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import config from '../../Config/config.prod.js';
import { Horizontal } from "../Gallery/Horizontal.jsx";
import { First } from "../Gallery/First";
import { Second } from "../Gallery/Second";
import { Third } from "../Gallery/Third";
import { Fourth } from "../Gallery/Fourth";





export const NewsCategory = (props)=>{
    
    const [topNews,setTopNews] = useState([]);
    const apiUrl = config.apiUrl;
    
    useEffect(()=>{
        async function getMainNews(){
            try{
                const topNews = await axios.get(`${apiUrl}/news/category/${props.category}`);

                setTopNews(topNews.data);

            }catch(error){
                console.log(error)
            }
        }
        getMainNews()
        
    },[apiUrl,props.category])

    
    return(
        <>
        {topNews.length>0?
        <div className="container max-[767px]:mt-24 max-[1084px]:mt-6">

            <First />

            {topNews.slice(0,1).map((content,key)=>(

                <div className="col-span-7" key={key}>
                <img alt={content.title} src={content.photo_url} className="w-full" loading="lazy"/>
                <i className="pt-1 pe-2 fa fa-calendar"> {content.neplai_date_format}</i>
                <Link to = {`/news/${content.news_slug}`}  className="hover:text-[#2260bf]"> <h1 className="pt-8 text-2xl font-bold text-justify text-[#23252d]"> {content.title}</h1></Link> 
                <p className=" pt-2 text-justify text-lg">{content.first_paragraph}</p>
                </div>  

            ))}
            <br/>
            <Second/>

            {topNews.slice(1,2).map((content,key)=>(  
                <div className="container" key = {key}>
                    <div>
                        <div className="flex max-[1023px]:flex-col ">
                            <img src={content.photo_url} alt={content.title} className="lg:w-1/2 w-full" loading="lazy"/>
                            <div className="lg:w-1/2 lg:p-24 p-4 bg-[#f0f8ff] w-full">
                                
                                <Link to={`/news/${content.news_slug}`}  className="hover:text-[#2260bf] ">
                                <h1 className="text-xl lg:text-4xl font-bold">{content.title}</h1> 
                                </Link>
                                <p className="pt-8 text-xl block ">{content.first_paragraph.slice(0,145)}...........</p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            <Third/>

            {topNews.slice(2,3).map((content,key)=>(  
                        <div className="container" key = {key}>
                            <div>
                                <div className="flex max-[1023px]:flex-col ">
                                    <img src={content.photo_url} alt={content.title} className="lg:w-1/2 w-full" loading="lazy"/>
                                    <div className="lg:w-1/2 lg:p-24 p-4 bg-[#f0f8ff] w-full">
                                        
                                        <Link to={`/news/${content.news_slug}`}  className="hover:text-[#2260bf] ">
                                        <h1 className="text-xl lg:text-4xl font-bold">{content.title}</h1> 
                                        </Link>
                                        <p className="pt-8 text-xl block ">{content.first_paragraph.slice(0,145)}...........</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}


            <Fourth/>

             <br/>
             {topNews.length>3?<h1 className="text-4xl font-extrabold text-[#2260bf] py-6">ताजा समाचार</h1>:<p><br/><br/></p>}
           
            <div className=" mt-6 grid grid-cols-3 gap-8 max-[767px]:grid-cols-1">
                {topNews.slice(3,Math.min(topNews.length, 10)).map((content,key)=> (
                    <Link key = {key} to={`/news/${content.news_slug}`} className="hover:text-[#2260bf] max-[767px]:flex gap-4">
                        <img className=" w-80  max-[767px]:w-28 " alt={content.title} src={content.photo_url} loading="lazy"/>
                        <h1 className="text-xl font-semibold pt-2">{content.title}</h1>
                    </Link>
                ))}
            </div>
            
            <Horizontal  />

        </div>:<div className = "h-[59vh]" > </div>
     
        }


        </>
    )
}


