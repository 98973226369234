import config from '../../Config/config.prod.js';
import React, {useState,useEffect} from "react";
import axios from "axios";

export const Second = ()=>{
    const apiUrl = config.apiUrl;
    const [second,setSecond] = useState([]);

    useEffect(()=>{
        async function getTodaysNews(){
            try{
                const seconda = await axios.get(`${apiUrl}/news/gallery/2`);
                setSecond(seconda.data);

            }catch(error){
                console.log(error)
            }
        }
        getTodaysNews()
    },[apiUrl])


    if (second.length === 0) {
        return (
          <>
          </>
        );
      }
      
    return (
        <>
        {/* <div className={second.length === 3?'container grid grid-cols-3 gap-3':second.length === 2?'container grid grid-cols-2 gap-3':'container'}> */}
        <div className='container grid grid-cols-3 gap-7 max-lg:grid-cols-1 '>
            {second.map((content, key) => (

                    <img src={content.photo_url} alt="content loaging" className="h-[65vh] max-lg:w-full max-lg:" key = {key}
                    loading="lazy"/>
            ))}
        </div>
      
        </>
    )

}
