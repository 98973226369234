import React from "react";
import { Link } from "react-router-dom";
export const TodaysNewsCard = (content,key)=>{

    return(
        <>
        <div className='container '>
        
                <div key={key} className="border-b border-grey-300 text-center">
                <Link to={`news/${content.content.news_slug}`} className="hover:text-[#2260bf]">
                    <h1 className="heading mt-8"> {content.content.title}</h1>
                    <img alt={content.content.title} src={content.content.photo_url} className="w-full max-h-[42rem] my-12" loading="lazy"/>
                    <p className="text-2xl mb-8">{content.content.first_paragraph.slice(0,200)}...</p>
                </Link>

                </div>
        </div>
        </>
    )
}