import React, {useState,useEffect} from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import config from '../../Config/config.prod.js';
import { Fourth } from "../../Components/Gallery/Fourth";

export const CategoryNews = ()=>{
    
    const [topNews,setTopNews] = useState([]);
    // const [similarData, setSimilarData] = useState([]);
    // const category = useMemo(() => ({ ...props }), [props]);
    const apiUrl = config.apiUrl;
    

    useEffect(()=>{
        async function getMainNews(){
            try{
                const topNews = await axios.get(`${apiUrl}/news/latest/`);
                setTopNews(topNews.data);

            }catch(error){
                console.log(error)
            }
        }
        getMainNews()
        
    },[apiUrl])


    // useEffect(() => {
    //     const similarNews = topNews.filter(obj => obj.category === category);
    //     if(similarNews.length === 0){
    //         setSimilarData(topNews.slice(2,17));


    //     }else{
    //     setSimilarData(similarNews);
    //     }
    //   }, [topNews,category,apiUrl]);
      
    
    return(
        <>
        {topNews.length>6?
        <div className="container">
            {topNews.slice(7,8).map((content,key)=>(

                    <div className="container" key = {key}>
                    <div>
                        <div className="flex max-[1023px]:flex-col ">
                            <img src={content.photo_url} alt={content.title} className="lg:w-1/2 w-full" loading="lazy"/>
                            <div className="lg:w-1/2 lg:p-24 p-4 bg-[#f0f8ff] w-full">
                                
                                <Link to={`/news/${content.news_slug}`}  className="hover:text-[#2260bf] ">
                                <h1 className="text-xl lg:text-4xl font-bold">{content.title}</h1> 
                                </Link>
                                <p className="pt-8 text-xl block ">{content.first_paragraph.slice(0,100)}. ...........</p>
                            </div>
                        </div>
                        <div></div> 
                    </div>
                    </div>
            ))}
            <Fourth/>
            <br/>
            <h1 className="text-4xl font-extrabold text-[#2260bf] py-6">ताजा समाचार</h1>

            <div className=" mt-6 grid grid-cols-3 gap-8 max-[767px]:grid-cols-1">
                {topNews.slice(8,Math.min(topNews.length, 20)).map((content,key)=> (
                    <Link key = {key} to={`/news/${content.news_slug}`} className="hover:text-[#2260bf] max-[767px]:flex gap-4">
                        <img className=" w-80  max-[767px]:w-28 " alt={content.title} src={content.photo_url} loading = "lazy"/>
                        <h1 className="text-xl font-semibold pt-2">{content.title}</h1>
                    </Link>
                ))}
            </div>
            <br/>

        </div>:<p></p>
        }


        </>
    )
}






