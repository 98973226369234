import './App.css';
import {Navbar} from './Components/Navbar/Navbar'
import {Footer} from './Components/Footer/Footer'
import  { Home } from "./Pages/Home/Home"
import  { Detail } from "./Pages/Detail/Detail"
import { Political } from "./Pages/Political/Political"
import { Sports } from "./Pages/Sports/Sports"
import { Business } from "./Pages/Business/Business"
import { Samaj } from './Pages/Samaj/Samaj';
import { International } from './Pages/International/International';
import { Literature } from './Pages/Literature/Literature';
import { Others } from './Pages/Others/Others';
import { Route, Routes } from 'react-router-dom';

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}



function App() {
  return (
   <>

   <Navbar/>
   <ScrollToTop />
   <Routes>
      <Route path= "/" element = {<Home/>}/>
      <Route path = "/politic" element ={<Political/>}/>
      <Route path = "/sports" element ={<Sports/>}/>
      <Route path = "/business" element ={<Business/>}/>
      <Route path = "/samaj" element ={<Samaj/>}/>
      <Route path = "/international" element ={<International/>}/>
      <Route path = "/literature" element ={<Literature/>}/>
      <Route path = "/others" element ={<Others/>}/>
      <Route path="/news/:news_slug" element = {<Detail/>}/>
   </Routes>
  <Footer/>

   </>
  );
}

export default App;
