import React  from "react";

import { NewsCategory } from "../../Components/News/NewsCategory";
import { Helmet } from "react-helmet-async";
export  const Sports =()=>{
    
    return(
        <>

            <Helmet>
                    <link rel="canonical" href="https://srskhavar.com/sports" />

                    <title>खेलकुद - SRSkhavar </title>
                    <meta
                    name="description"
                    content="Get all the sports news"
                    />
                    <meta
                    name = "keywords"
                    content="srskhavar, srs khavar, srs khabar, news, neplai samachar, samachar, srs, srsnews, srskhabar, online news, online news, onlinekhabar, online khabar, kantipur, nepali news, srskhavar, news, nepali news,
                     cricket, football, cricket news, football news, nepali storts, nepal cricket, cricinfo, cricnepal, espncricinfo, laliga, epl, msl, *,"
                    />
            </Helmet>
            <NewsCategory category = "sports"/>
        
        </>
       
        )

}