import React  from "react";
import { NewsCategory } from "../../Components/News/NewsCategory";
import { Helmet } from "react-helmet-async";
export  const Samaj =()=>{
    
    return(
        <>

            <Helmet>
                    <link rel="canonical" href="https://srskhavar.com/samaj" />

                    <title>समाज - SRSkhavar </title>
                    <meta
                    name="description"
                    content="Get all the social news"
                    />
                    <meta
                    name = "keywords"
                    content="srskhavar, srs khavar, srs khabar, news, neplai samachar, samachar, srs, srsnews, srskhabar, online news, online news, onlinekhabar, online khabar, kantipur, nepali news, srskhavar, news, nepali news,  political news, politic, todays politic, राजनीतिक,nepali,*"
                    />
            </Helmet>

            <div>
                <NewsCategory category = "social"/>

            </div>
        </>
     
        )

}