import React  from "react";

import { TodaysNews } from '../../Components/News/TodaysNews'
import { CategoryNews } from "../../Components/News/CategoryNews";
import { LatestNews } from "../../Components/News/LatestNews";

import { First } from "../../Components/Gallery/First"
import { Second } from "../../Components/Gallery/Second"
import { Third } from "../../Components/Gallery/Third"

// import { Verticle } from "../Gallery/Verticle";
import { Horizontal } from "../../Components/Gallery/Horizontal";

 import { Helmet } from "react-helmet-async";
export  const Home =()=>{
    
    return(
        
    <>
        <Helmet>
            <title>SRSkhavar - Home </title>
            <meta
            name="description"
            content="Get all the latest news"
            />
            <meta
            name = "keywords"
            content="srskhavar, srs khavar, srs khabar, news, neplai samachar, samachar, srs, srsnews, srskhabar, online news, online news, onlinekhabar, online khabar, kantipur, nepal cricket, nepali news, business, economy, stockmarket, stock market, trade, srskhavar, news, nepali news,*"
            />
            <link rel="canenocial" href="/"/>
        </Helmet>
        <div className="max-[767px]:mt-24">
            <First/>
            <TodaysNews />
            <Second/>
            <LatestNews />
            <Third/>
            <CategoryNews/>
            <Horizontal/>
        </div>
    </>

    )

}