import React  from "react";
import { Link } from "react-router-dom";


export const NavLarge = (props)=>{
    const {toggle_state} = props;

    // const stylee =  navbar?"bg-[#0d6efd] w-full py-1 font-bold text-white hidden fixed top-0 md:block ":"bg-[#0d6efd] w-full py-1 font-bold text-white hidden md:block"

    const stylee =  "sticky top-0 bg-[#0d6efd] w-full py-1 font-bold text-white hidden md:block "

    return(
        <>
        {/* #f4f6f8 */}
            <nav className={toggle_state?"bg-slate-200 rounded w-1/2 h-full fixed right-0 mt-[50px] top-0 max-[767px]:drop-shadow-md":stylee }>
            <div className={toggle_state?(""):'container'}>
                <ul className={toggle_state?"flex flex-col gap-5 items-center justify-between mt-16 text-2xl text-[#0d6efd] font-bold":'flex text-2xl justify-between max-w-[70rem'} >
                    <li className={toggle_state?"border-b-2 border-white w-full text-center py-3":""}>
                        <Link to="/">गृहपृष्ठ</Link>
                    </li>
                    <li className={toggle_state?"border-b-2 border-white w-full text-center py-3":""}>
                        <Link  to="/politic">राजनीति</Link>
                    </li>
                    <li className={toggle_state?"border-b-2 border-white w-full text-center py-3":""}>
                        <Link  to="/business">आर्थिक</Link>
                    </li>
                    <li className={toggle_state?"border-b-2 border-white w-full text-center py-3":""}>
                        <Link to="/samaj">समाज</Link>
                    </li>
                    <li className={toggle_state?"border-b-2 border-white w-full text-center py-3":""}>
                        <Link to="/international">अन्तर्राष्ट्रिय</Link>
                    </li>
                    <li className={toggle_state?"border-b-2 border-white w-full text-center py-3":""}>
                        <Link  to="/sports">खेलकुद</Link>
                    </li>

                    <li className={toggle_state?"border-b-2 border-white w-full text-center py-3":""}>
                        <Link  to="/literature">साहित्य</Link>
                    </li>

                    <li className={toggle_state?"border-b-2 border-white w-full text-center py-3":""}>
                        <Link to="/others">अन्य</Link>
                    </li>
                </ul>
            </div>

        </nav>
        </>
    )
}