import React from "react";

export const Footer = ()=>{
    return(
    <>
    <footer className="bottom-0 bg-[#2260bf24] md:px-16 py-5 text-xs w-full z-10 static ">
        <div className="container">
            <div className="grid grid-cols-2 lg:grid-cols-6 max-[369px]:flex flex-col items-center">
                <div className="pr-4 lg:border-r border-r-[#00000024] col-span-2 lg:col-span-1 mb-4 lg:mb-0">
                    <img alt='logo' src="logo.webp" className="max-w-[240px] w-full"  loading='eager' />
                </div>
                <div className="ml-8 max-[370px]:text-center max-[370px]:ml-0 lg:border-r border-r-[#00000024] mb-4 lg:mb-0">
                    <p> अध्यक्ष तथा प्रबन्ध निर्देशक: </p>
                    संस्कार भट्टराई
                </div>
                <div className="ml-8 max-[370px]:text-center max-[370px]:ml-0 lg:border-r border-r-[#00000024] mb-4 lg:mb-0">
                    <p> प्रधान सम्पादकः </p> 
                    संस्कार भट्टराई
                </div>
                {/* <div className="ml-8 max-[370px]:text-center max-[370px]:ml-0 lg:border-r border-r-[#00000024] mb-4 lg:mb-0">
                    <p> सूचना विभाग दर्ता नं. २१४ / </p>  
                    ०७३–७४
                </div> */}
                <div className="ml-8 max-[370px]:text-center max-[370px]:ml-0 lg:border-r border-r-[#00000024] mb-4 lg:mb-0 text-[1rem]">
                    <p>+977 9765311878</p> 
                    news@srs.com
                </div>
                <div className="ml-8 max-[370px]:text-center max-[370px]:ml-0">
                    <p>
                        <a href="https://www.facebook.com/profile.php?id=61554444809158&mibextid=kFxxJD" target="_blank"  rel="noreferrer"><i className="fa-brands fa-facebook-f text-blue-600 text-xl"></i></a>
                        <a href="/#" className=" px-3" target="_blank"  rel="noreferrer"><i className="fa-brands fa-twitter text-blue-600 text-xl"></i></a>
                        <a href="/#" target="_blank"  rel="noreferrer"><i className="fa-brands fa-youtube text-red-600 text-xl" ></i></a>
                    </p> 
                    2022-2023 srskhavar.com
                    सर्वाधिकार सुरक्षित
                </div>
            </div>
            <div className="p-2 mt-4 ">
                Copyright SRS 2023. All Rights Reserved | Terms & Conditions | Privacy Policy | Disclaimer
            </div>
        </div>
    </footer>
    </>
    )

}