import React  from "react";
import { useParams } from "react-router-dom";
import { Horizontal } from "../../Components/Gallery/Horizontal";
import { Details } from "../../Components/Details/Details";
import { Similar } from "../../Components/Details/Similar";
import { YouMayLike } from "../../Components/Details/YouMayLike";


export  const Detail =()=>{
    const { id } = useParams()    
    return(
        <>
            <div className="container border-b border-gray-300 flex flex-row max-[1026px]:flex-col">
                <div>
                    <Details id = {id} />
                    <Similar id = {id}/>
                    <Horizontal/>
                    <YouMayLike id = {id}/>   
                </div>
            </div>

            
        </>
    )

}

