import React, {useState,useEffect} from "react";
import { FacebookShareButton,FacebookIcon,ViberShareButton, TwitterShareButton,TwitterIcon, ViberIcon } from 'react-share';


import axios from "axios";
import { useParams } from "react-router-dom";

import { First } from "../Gallery/First"
import { Second } from "../Gallery/Second"
import { Third } from "../Gallery/Third"
import config from '../../Config/config.prod.js';
import { Fourth } from "../Gallery/Fourth";

// import { Helmet } from "react-helmet-async";



export const Details = ()=>{
    const {news_slug} = useParams()
    const [News,setNews] = useState([])
    const apiUrl = config.apiUrl
    const currentURL = `https://srskhavar.com/news/${news_slug}`

    const useMetaTag = (title,description) => {
        const defaultTitle = "SRSkhavar";
        const defaultDescription = "This is SRS Khavar webpage content";
        const newDesc = description || defaultDescription
        const metaTag = document.getElementById('description-meta-tag');

        
      
        useEffect(() => {
          document.title = title || defaultTitle;
          
          metaTag.setAttribute('content',newDesc );

          document.querySelector("meta[name='description']").setAttribute("content", description || defaultDescription);

        }, [defaultTitle, title,defaultDescription,description,newDesc,metaTag]);
      };
 

    useEffect(()=>{
        async function getNews(){
            try{
                const News = await axios.get(`${apiUrl}/news/details/${news_slug}`);
                setNews(News.data);
                // document.title = News.data[0].title + "- SRSkhabar"
                    
    
            }catch(error){
                console.log(error)
            }
        }
        getNews()
    },[apiUrl,news_slug])

  
    



   

    return(
        <>
            {
                useMetaTag(News[0]?.title,News[0]?.first_paragraph)
            
            }
            {/* {useMetaTag(News[0].title)} */}
            {News.map((content,key)=>{

                // const title=  content.title
                // const currentUrl = {`/news/details/${news_slug}`}
                // const quote = {content.title}
                // const description = {content.first_paragraph.slice(0,10)}
                // const hashtag = '#srskhavar'
                // const image = {content.photo_url}

                
                return(
                    <>

                    {/* <Helmet key = {key}>
                        <title>{content.title}</title>
                        <meta property="og:title" content={content.title}/>
                        <meta property="og:type" content="article" />
                        <meta property="og:image" content={content.photo_url}/>
                        <meta property="og:url" content={currentURL}/>
                        <meta name="twitter:card" content="summary_large_image"/>

                        <meta property="og:description" content={content.first_paragraph}/>
                        <meta property="og:site_name" content="Srskhavar"/>
                        <meta name="twitter:image:alt" content={content.photo_url}/>   
                    </Helmet> */}

                    <div className='mt-2 max-[767px]:mt-24' key = {key}>
                
                        <First />
                        <h1 className=" mt-2 flex justify-center text-6xl max-[739px]:text-5xl max-[598px]:text-3xl max-[385px]:text-xl font-bold"> {content.title}</h1>
                        <div className="flex m-2  flex-row gap-1">
                            <p className=" text-[#0000]-600 text-xl  border border-gray-200 p-2 rounded-2xl bg-gray-100 shadow-lg m-4 max-[767px]:text-[1rem]">Share on <i className="fa-sharp fa-solid fa-share-nodes text-xs" ></i></p>
                            
                            <FacebookShareButton url={currentURL} quote={content.title}>
                                 <FacebookIcon size={50} className="rounded-full  border-gray-200 bg-gray-100 shadow-lg"/>
                            </FacebookShareButton>
                            <TwitterShareButton url={currentURL} quote={content.title}>
                                <TwitterIcon size={50} className="rounded-full  border-gray-200 bg-gray-100 shadow-lg"/>
                            </TwitterShareButton>
                            <ViberShareButton url={currentURL} quote={content.title}>
                                <ViberIcon size={50} className="rounded-full  border-gray-200 bg-gray-100 shadow-lg"/>
                            </ViberShareButton>

                        </div>

                    
                        <hr />
                        <img alt={content.title} src={content.photo_url} loading="lazy" className="w-full max-h-[42rem] my-12"/>
                        {/* <p className="text-2xl text-justify max-[600px]:1xl">{content.first_paragraph}</p> */}
                        <p className="text-2xl text-justify max-[600px]:1xl" dangerouslySetInnerHTML={{ __html: content.first_paragraph }}/>

                        <br/>
                        <Second />
                        {/* <p className="text-2xl text-justify max-[600px]:1xl">{content.second_paragraph}</p> */}
                        <p className="text-2xl text-justify max-[600px]:1xl" dangerouslySetInnerHTML={{ __html: content.second_paragraph }}/>
                        <br/>
                        <Third/>
                        {/* <p className="text-2xl text-justify max-[600px]:1xl">{content.third_paragraph}</p> */}
                        <p className="text-2xl text-justify max-[600px]:1xl" dangerouslySetInnerHTML={{ __html: content.third_paragraph }}/>

                        <div className="flex m-2  flex-row gap-1">
                            <p className=" text-[#0000]-600 text-xl  border border-gray-200 p-2 rounded-2xl bg-gray-100 shadow-lg m-4 max-[767px]:text-[1rem]">Share on <i className="fa-sharp fa-solid fa-share-nodes text-xs" ></i></p>      
                            <FacebookShareButton url={`https://srskhavar.com/news/${news_slug}`} quote={content.title}>
                                 <FacebookIcon size={50} className="rounded-full  border-gray-200 bg-gray-100 shadow-lg"/>
                            </FacebookShareButton>
                            <TwitterShareButton url={`https://srskhavar.com/news/${news_slug}`} quote={content.title}>
                                <TwitterIcon size={50} className="rounded-full  border-gray-200 bg-gray-100 shadow-lg"/>
                            </TwitterShareButton>
                            <ViberShareButton url={`https://srskhavar.com/news/${news_slug}`} quote={content.title}>
                                <ViberIcon size={50} className="rounded-full  border-gray-200 bg-gray-100 shadow-lg"/>
                            </ViberShareButton>
                        </div>
                        <Fourth/>
                    </div> 
                    </>                   
                )
            })}

        </>
    )
}