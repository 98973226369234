import React, {useState,useEffect} from "react";
import axios from "axios";
import { TodaysNewsCard } from "../NewsCards/TodaysNewsCard";
import config from '../../Config/config.prod.js';

export const TodaysNews = ()=>{
    const apiUrl = config.apiUrl;
    
    const [todaysNews,setTodaysNews] = useState([])
    useEffect(()=>{
        async function getTodaysNews(){
            try{
                const todaysNews = await axios.get(`${apiUrl}/news/latest/`);

                setTodaysNews(todaysNews.data);

            }catch(error){
                console.log(error)
            }
        }
        getTodaysNews()
    },[apiUrl])
    return(
        <>
        {todaysNews.slice(0,2).map((content,index)=>{

            return(
                <TodaysNewsCard content = {content} key={index}/>
            )
        })}
        </>
    )
}