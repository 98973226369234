import config from '../../Config/config.prod.js';
import React, {useState,useEffect} from "react";
import axios from "axios";

export const Horizontal = ()=>{
    const apiUrl = config.apiUrl;
    const [other,setOther] = useState([]);
    // const length = 3;
    useEffect(()=>{
        async function getAds(){

            try{
                const otherd = await axios.get(`${apiUrl}/news/gallery/other`);


                setOther(otherd.data);

            }catch(error){
                
                console.log(error)
            }
        }
        getAds()
    },[apiUrl])



    if (other.length === 0) {
        return (
          <>
          </>
        );
      }
    
    return (

        
        <>
        <div className='container grid grid-cols-3 gap-7 max-lg:grid-cols-1 '>
            {other.map((content, key) => (

            <img src={content.photo_url} alt="content loaging" className="h-[65vh] max-lg:w-full max-lg:" key = {key}
            loading="lazy"/>
            ))}
        </div>
      
        </>
    )

}

// <div className={other.length === 3?'container grid grid-cols-3 gap-3':other.length === 2?'container grid grid-cols-2 gap-3':'container'}>
// {other.map((content, key) => (

//         <img src={content.photo_url} alt="content loaging" className="w-2/5 h-full lg:h-full" key = {key} loading="lazy"
//          />
// ))}
// </div> 

