import React, {useState,useEffect} from "react";
import { Link} from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import config from '../../Config/config.prod.js';



export  const Similar =()=>{ 
    const {news_slug} = useParams()
    const [similarNews,setSimilarNews] = useState([])
    const apiUrl = config.apiUrl;
   

    useEffect(()=>{
        async function getNews(){
            try{
                const News = await axios.get(`${apiUrl}/news/similar/${news_slug}`);
                setSimilarNews(News.data);
    
            }catch(error){
                console.log(error)
            }
        }
        getNews()
    },[apiUrl,news_slug])


    return(
        <>
            <div>
                <h3 className="text-4xl font-extrabold text-[#2260bf] py-6">सम्बन्धित खबर</h3>
                <div className="grid grid-cols-3 gap-8 max-[500px]:grid-cols-1">
                    {similarNews.map((content, key) => (

                        <Link to={`/news/${content.news_slug}`} className="hover:text-[#2260bf] max-[500px]:flex gap-4" key = {key}>
                            <img className="max-[500px]:w-28  w-[80%] h-[60%]"  alt={content.title} src={content.photo_url} loading="lazy"/>
                            <p className="text-xl font-bold pt-4">{content.title}</p>
                        </Link>


                    ))}                    
                </div>
            </div>

            
        </>
    )

}


