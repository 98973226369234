import React  from "react";

import { NewsCategory } from "../../Components/News/NewsCategory";
import { Helmet } from "react-helmet-async";

export  const International =()=>{
    
    return(
        <>
        <Helmet>
            <title>अन्तर्राष्ट्रिय - SRSkhavar </title>
            <meta
            name="description"
            content="Get all the international news"
            />
            <meta
            name = "keywords"
            content="srskhavar, srs khavar, srs khabar, news, neplai samachar, samachar, srs, srsnews, srskhabar, online news, online news, onlinekhabar, online khabar, kantipur, nepali news, srskhavar, news, nepali news,  international news, international,*"
            />
            <link rel="canenocial" href="/"/>
        </Helmet>
        <NewsCategory category = "international"/>
        
        </>
     
        )

}