import React  from "react";
import { Helmet } from "react-helmet-async";

import { NewsCategory } from "../../Components/News/NewsCategory";

export  const Business =()=>{
    
    return(
        <>
        <Helmet>
           <link rel="canonical" href="https://srskhavar.com/business" />
            <title>विजनेस - srskhavar</title>
            <meta
            name="description"
            content="Get all the latest news about business economy trade and stock market"
            />
            <meta
            name = "keyword"
            content="business, economy, stockmarket, stock market, trade, srskhavar, news, nepali news,"
            />
        </Helmet>
       

        <div>
            <NewsCategory category = "economic"/>

        </div>
        </>
     
        )

}