import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { NavLarge } from "./NavLarge";
import config from '../../Config/config.prod.js';


export const Navbar = ()=>{
    const [toggleClicked,setToggleClicked]=useState(false);
    const [todaysDate,setTodaysDate] = useState(false);
    const [screenSize,setScreenSize] =useState(getCurrentSize())
    // const [smallnavbar,setSmallNavbar] = useState(false);
    const apiUrl = config.apiUrl;


    function getCurrentSize(){
        return{
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

   useEffect(()=>{
    const updateSize = ()=>{
        setScreenSize(getCurrentSize());
        if (screenSize.width>767){
            setToggleClicked(false)

    
        }
        // else{
        //     setSmallNavbar(true)
            
        // }
    }
    window.addEventListener('resize',updateSize);
    return(()=>{
        window.removeEventListener('resize',updateSize);
    })
   },[screenSize])


//    const [navbar,setNavbar] = useState(false);
//    const fixnavbar = ()=>{
//        if(window.scrollY <= 120){
//            setNavbar(false)
//        }else{
//            setNavbar(true)
//        }

//    }
//    window.addEventListener('scroll',fixnavbar)

        
//    const style = {
//        position: navbar ? 'fixed' : 'static',
//        top: navbar ? '0' : 'auto',
//        left: navbar ? '0' : 'auto',

      
//    };

//    const styleSmallNav = {
//     position: smallnavbar ? 'fixed' : '',
//     top: smallnavbar ? '0' : '',
//     left: smallnavbar ? '0' : '',

//    }



    useEffect( ()=>{
        async function get_date(){
            try{
                const todays_date = await axios.get(`${apiUrl}/date/`);
                setTodaysDate(todays_date.data);

            }catch(err){
                console.log(err)
            }
        }
        get_date()

    },[apiUrl])

    
    return (
        <>
        <header>
            <div className='container z-40 flex my-4 justify-between max-[767px]:drop-shadow-xl max-[767px]:fixed max-[767px]:bg-slate-200	 max-[767px]:mb-28 max-[767px]:mt-0 max-[767px]:top-0 md:justify-normal max-[767px]:pb-1'>
                <div className='w-80'>
                    <img alt='logo' src="logo.webp" className="max-[500px]:max-w-[80%]" loading='eager'></img>

                    <div className='float-right  text-[0.8rem] text-gray-600 max-[767px]:float-none max-[767px]:text-center'>{todaysDate['todays_date']}</div>
                </div>
               
                <button onClick={()=>setToggleClicked(!toggleClicked)} className="p-1">
                     <span><i className='fa-solid fa-bars fa-xl md:hidden'></i></span>
                </button>
            </div>
        </header>

        <NavLarge toggle_state={toggleClicked}/> 
        </>
    )

}
