import config from '../../Config/config.prod.js';
import React, {useState,useEffect} from "react";
import axios from "axios";

export const First = ()=>{
    const apiUrl = config.apiUrl;
    const [first,setFirst] = useState([]);
    useEffect(()=>{
        async function getAds(){

            try{
                const firstd = await axios.get(`${apiUrl}/news/gallery/1`);

                setFirst(firstd.data);

            }catch(error){
                
                console.log(error)
            }
        }
        getAds()
    },[apiUrl])


    if (first.length === 0) {
        return (
          <>
          </>
        );
      }


    
    return (
        <>
        <div className='container grid grid-cols-3 gap-7 max-lg:grid-cols-1 '>
            {first.map((content, key) => (

                    <img src={content.photo_url} alt="content loaging" className="h-[65vh] max-lg:w-full max-lg:" key = {key}
                    loading="lazy"/>
            ))}
        </div>
        
       
        </>
    )

}

//<div className={first.length === 3?'container grid grid-cols-3 gap-3':first.length === 2?'container grid grid-cols-2 gap-3':'container'}>
  //  {first.map((content, key) => (
    //    <img src={content.photo_url} alt="content loaging" className="w-2/5 h-full lg:h-full max-[1084px]:mb-6" key = {key}  loading="lazy"/>
//))}
//</div> 
